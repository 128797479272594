<template>
  <div class="page-class page-user-list">
    <h1 class="page-title text-center">{{ title }}</h1>
    <feedback-list-box
      :columns="columns"
      :rows="customerNumberList"
      :baseUrl="baseUrl"
      :loading="loading"
      :showAdd="false"
      :showDownload="true"
      :showToolbar="true"
      :showPagination="true"
      :pagination="pagination"
      :searchItems="searchItems"
      :steps="steps"
      :callbacks="callbacks"
      uploadText="Upload Customer Numbers List"
      downloadText="Download Customer Numbers List"
      emptyText="No Customer Numbers found. Please add new Numbers."
      :showUpload="true"
      @delete="onDelete"
      @download="onExportCSV"
      @show-upload="onShowUpload"
    >
      <template v-slot:emailAddressFormatted="slotProps">
        <span class="span-text span-block">
          {{ slotProps.row.email }}
        </span>
        <template v-if="slotProps.row.confirmedAtFormatted">
          <span class="text-success span-help-text" :title="slotProps.row.confirmedAt">
            ({{ slotProps.row.confirmedAtFormatted }})
          </span>
        </template>
        <template v-if="slotProps.row.confirmedAtFormatted === null">
          <span class="text-warning span-help-text"> (Not confirmed) </span>
        </template>
      </template>
      <template v-slot:lastLoginAtFormatted="slotProps">
        <template v-if="slotProps.row.lastLoginAtFormatted">
          <span class="text-success">{{ slotProps.row.lastLoginAtFormatted }}</span>
        </template>
        <template v-if="slotProps.row.lastLoginAtFormatted == null">
          <span class="text-warning">Never logged in</span>
        </template>
      </template>
      <template v-slot:blockedAtFormatted="slotProps">
        <template v-if="slotProps.row.blockedAtFormatted">
          <span class="text-success">{{ slotProps.row.blockedAtFormatted }}</span>
        </template>
        <template v-if="slotProps.row.blockedAtFormatted == null">
          <span class="text-warning">Never logged in</span>
        </template>
      </template>
    </feedback-list-box>
  </div>
</template>

<script>
import moment from 'moment';
import Vue from 'vue';
import csv from 'csvtojson';
import { mapState, mapActions } from 'vuex';
import router from '@/router';
import _ from 'lodash';
import FeedbackListBox from '../../components/FeedbackListBox.vue';

export default {
  name: 'CustomerList',
  components: { FeedbackListBox },
  metaInfo() {
    return {
      title: 'Bulk Customers',
      meta: [
        {
          name: 'description',
          content: `Manage Customer Numbers`
        }
      ]
    };
  },
  mounted() {
    // if (window.localStorage.getItem('hasCompletedCustomerlist') !== 'true') {
    //   this.$tours.myTour.start();
    // }
    if (_.isEmpty(this.$route.query)) {
      this.listCustomer({
        query: {
          ...this.$route.query,
          startdate: moment().format('YYYY-MM-DD'),
          enddate: moment().format('YYYY-MM-DD')
        }
      });
    } else {
      this.listCustomer({ query: this.$route.query });
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.listCustomer({ query: to.query });
    next();
  },
  data() {
    return {
      title: 'Whatsapp Broadcast Customer Numbers List',
      callbacks: {
        onFinish() {
          window.localStorage.setItem('hasCompletedCustomerlist', 'true');
        }
      },
      steps: [
        {
          target: '[data-v-step="0"]', // We're using document.querySelector() under the hood
          content: `Enter search criteria based on Customer Number or Store Id to Filter the List.`,
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="2"]',
          content: 'Select Date Range to further refine search results and click Apply button.',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="4"]', // We're using document.querySelector() under the hood
          content: `Download the list of Customer Numbers in CSV format.`,
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="5"]',
          content: 'Upload the list of Customer Numbers in CSV format.',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        }
      ],
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { ' text-center': true },
          textKey: 'rowNum',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Store Id',
          class: { 'text-center': true },
          textKey: 'storeid',
          width: '18%'
        },
        {
          type: 'string',
          headerText: 'Customer Number',
          class: { 'text-center': true },
          textKey: 'customerNumber',
          width: '25%'
        },

        {
          type: 'string',
          headerText: 'Created At',
          class: { 'text-center': true },
          textKey: 'createdAt',
          width: '22%'
        },

        {
          type: 'string',
          headerText: 'Submitted By ',
          class: { 'text-center': true },
          textKey: 'submittedByName',
          width: '20%'
        },

        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '7%',
          functions: {
            edit: false,
            delete: true
          }
        }
      ],
      searchItems: ['Customer Number', 'Store Id']
    };
  },
  computed: {
    ...mapState('customerNumber', ['loading', 'baseUrl', 'customerNumberList', 'pagination', 'downloadNumberList']),
    ...mapState('darkMode', ['isDarkMode'])
  },
  watch: {
    downloadNumberList(newValue) {
      if (newValue.length) {
        let csv = 'Row Number,Store ID,Customer Number, Craeted At, Submitted By\n';
        newValue.forEach(row => {
          Object.values(row).forEach((val, index) => {
            if ([1, 2, 3, 4, 5].includes(index)) {
              csv += `"${val}"`;
              csv += ',';
            }
          });
          csv += '\n';
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'Bulk Customers.csv';
        anchor.click();
      }
    }
  },
  methods: {
    ...mapActions('customerNumber', ['listCustomer', 'deleteOne', 'upload', 'download']),
    onDelete({ row }) {
      Vue.swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this.",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        },
        preConfirm: () => {
          this.deleteOne({
            id: row.id
          });
        }
      });
    },

    onExportCSV() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.download({ query: { ...this.$route.query, page_size: 99999999, page: 1 } });
      } else {
        this.download({
          query: {
            ...this.$route.query,
            page_size: 9999999,
            page: 1,
            startdate: moment().subtract(1, 'months').format('MMM YYYY')
          }
        });
      }
    },
    async onShowUpload() {
      const { value: bulkCustomerFile } = await Vue.swal({
        title: 'Upload file for Customer Numbers..',
        input: 'file',
        inputAttributes: {
          'aria-label': 'Upload your Customer Numbers file in csv format'
        },
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Upload',
        showCancelButton: true,
        cancelButtonText: 'Close',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        },
        footer:
          '<a href="https://spencer-template.s3.ap-south-1.amazonaws.com/BulkCustomer.csv" >Download sample upload file</a>'
      });
      if (bulkCustomerFile) {
        const reader = new FileReader();
        reader.onload = () => {
          csv({
            noheader: true,
            output: 'csv'
          })
            .fromString(reader.result)
            .then(csvRow => {
              const inputs = csvRow.slice(1, csvRow.length).map(item => {
                return {
                  storeid: item[0].toUpperCase(),
                  customerNumber: item[1]
                };
              });
              this.upload({ router, data: inputs });
            });
        };
        reader.readAsBinaryString(bulkCustomerFile);
      }
    }
  }
};
</script>
